<template>
    <div
        class="overflow-y-auto mx-0 px-0 py-0 mt-3"
        :style="{ height: `${this.height}px` }"
    >
        <v-row no-gutters class="mx-4">
            <v-col cols="12">
                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="headers"
                    :items="filters"
                    item-key="ident"
                    class="elevation-0 clickable-row"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    @click:row="openCost"
                    :height="'75vh'"
                    fixed-header
                >
                    <template v-slot:top>
                        <div class="top">
                            <v-row
                                no-gutter
                                class="mt-0 mx-0 pt-4 pb-0 pb-4 align-center"
                                :style="{ 'background-color': '#eeeeee' }"
                            >
                                <h2 class="ml-4 mr-4">
                                    ESTIMATING PLAN
                                </h2>
                                <v-btn
                                    small
                                    icon
                                    @click="openQuoteSchedule"
                                    color="primary"
                                >
                                    <v-icon>
                                        mdi-calendar
                                    </v-icon>
                                </v-btn>
                                <v-spacer />
                                <v-btn
                                    :disabled="costs.length == 0"
                                    class="mr-3"
                                    depressed
                                    color="primary"
                                    @click="downloadReport"
                                    rounded
                                >
                                    <v-icon class="mr-2"
                                        >mdi-download-outline</v-icon
                                    >
                                    Eng Report
                                </v-btn>
                            </v-row>
                            <v-row
                                no-gutter
                                :style="{ 'background-color': '#eeeeee' }"
                                :class="
                                    $vuetify.breakpoint.xs
                                        ? 'd-flex justify-center pb-4 mx-0'
                                        : 'd-flex justify-start pb-4 mx-0'
                                "
                            >
                                <div class="d-flex">
                                    <template>
                                        <v-card
                                            class="pt-5 px-2 ma-2"
                                            min-width="250px"
                                            max-width="250px"
                                        >
                                            <v-card-text
                                                class="d-flex my-0 py-0 mx-0 px-0"
                                                width="100%"
                                            >
                                                <v-chip
                                                    class="mb-1 mt-0 mx-2 pt-0 white--text"
                                                    :color="insights[0].color"
                                                    label
                                                    :style="{ height: '50px' }"
                                                >
                                                    <v-icon center>
                                                        {{ insights[0].icon }}
                                                    </v-icon>
                                                </v-chip>
                                                <div class="mb-1 mt-0 pt-0">
                                                    <div
                                                        class="d-flex mr-0 pr-0 text-capitalize"
                                                    >
                                                        <span
                                                            :style="{
                                                                'font-size':
                                                                    '20px',
                                                                'font-weight':
                                                                    'bold',
                                                            }"
                                                            >{{
                                                                insights[0]
                                                                    .title
                                                            }}</span
                                                        >
                                                    </div>
                                                    <p
                                                        class="text-h6 black--text"
                                                    >
                                                        {{
                                                            filters.filter(
                                                                u =>
                                                                    u.quote &&
                                                                    u.quote
                                                                        .status ==
                                                                        'OPEN'
                                                            ).length
                                                        }}
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </div>

                                <div class="d-flex">
                                    <template>
                                        <v-card
                                            class="pt-5 px-2 ma-2"
                                            min-width="250px"
                                            max-width="250px"
                                        >
                                            <v-card-text
                                                class="d-flex my-0 py-0 mx-0 px-0"
                                                width="100%"
                                            >
                                                <v-chip
                                                    class="mb-1 mt-0 mx-2 pt-0 white--text"
                                                    :color="insights[1].color"
                                                    label
                                                    :style="{ height: '50px' }"
                                                >
                                                    <v-icon center>
                                                        {{ insights[1].icon }}
                                                    </v-icon>
                                                </v-chip>
                                                <div class="mb-1 mt-0 pt-0">
                                                    <div
                                                        class="d-flex mr-0 pr-0 text-capitalize"
                                                    >
                                                        <span
                                                            :style="{
                                                                'font-size':
                                                                    '20px',
                                                                'font-weight':
                                                                    'bold',
                                                            }"
                                                            >{{
                                                                insights[1]
                                                                    .title
                                                            }}</span
                                                        >
                                                    </div>
                                                    <p
                                                        class="text-h6 black--text"
                                                    >
                                                        {{
                                                            filters.filter(
                                                                u =>
                                                                    u.engineering ==
                                                                        true &&
                                                                    u.quote &&
                                                                    u.quote
                                                                        .status !=
                                                                        'SENT'
                                                            )?.length
                                                        }}
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </div>

                                <div class="d-flex">
                                    <template>
                                        <v-card
                                            class="pt-5 px-2 ma-2"
                                            min-width="250px"
                                            max-width="250px"
                                        >
                                            <v-card-text
                                                class="d-flex my-0 py-0 mx-0 px-0"
                                                width="100%"
                                            >
                                                <v-chip
                                                    class="mb-1 mt-0 mx-2 pt-0 white--text"
                                                    :color="insights[2].color"
                                                    label
                                                    :style="{ height: '50px' }"
                                                >
                                                    <v-icon center>
                                                        {{ insights[2].icon }}
                                                    </v-icon>
                                                </v-chip>
                                                <div class="mb-1 mt-0 pt-0">
                                                    <div
                                                        class="d-flex mr-0 pr-0 text-capitalize"
                                                    >
                                                        <span
                                                            :style="{
                                                                'font-size':
                                                                    '20px',
                                                                'font-weight':
                                                                    'bold',
                                                            }"
                                                            >{{
                                                                insights[2]
                                                                    .title
                                                            }}</span
                                                        >
                                                    </div>
                                                    <p
                                                        class="text-h6 black--text"
                                                    >
                                                        {{
                                                            filters.filter(
                                                                u =>
                                                                    u.quote &&
                                                                    u.quote
                                                                        .status ==
                                                                        'APPROVAL'
                                                            ).length
                                                        }}
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </div>

                                <div class="d-flex">
                                    <template>
                                        <v-card
                                            :disabled="!loadFollowUp || loading"
                                            class="pt-5 px-2 ma-2"
                                            min-width="250px"
                                            max-width="250px"
                                            @click.stop="
                                                loadData('SENT'),
                                                    (loadFollowUp = !loadFollowUp)
                                            "
                                        >
                                            <v-card-text
                                                class="d-flex my-0 py-0 mx-0 px-0"
                                                width="100%"
                                            >
                                                <v-chip
                                                    class="mb-1 mt-0 mx-2 pt-0 white--text"
                                                    :color="insights[3].color"
                                                    label
                                                    :style="{ height: '50px' }"
                                                >
                                                    <v-icon center>
                                                        {{ insights[3].icon }}
                                                    </v-icon>
                                                </v-chip>
                                                <div class="mb-1 mt-0 pt-0">
                                                    <div
                                                        class="d-flex mr-0 pr-0 text-capitalize"
                                                    >
                                                        <span
                                                            :style="{
                                                                'font-size':
                                                                    '20px',
                                                                'font-weight':
                                                                    'bold',
                                                            }"
                                                            >{{
                                                                insights[3]
                                                                    .title
                                                            }}</span
                                                        >
                                                    </div>
                                                    <p
                                                        class="text-h6 black--text"
                                                    >
                                                        {{
                                                            filters.filter(
                                                                u =>
                                                                    u.quote &&
                                                                    u.quote
                                                                        .status ==
                                                                        'SENT'
                                                            ).length
                                                        }}
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </div>
                            </v-row>
                        </div>
                    </template>
                    <!--HEADERS-->
                    <template v-slot:[`header.quote.name`]="{ header }">
                        <v-combobox
                            v-model="quotesToFilter"
                            :items="quotesArray"
                            :label="header.text"
                            flat
                            solo
                            background-color="#eeeeee"
                            multiple
                            item-text="name"
                            item-value="id"
                            hide-details
                        >
                            <template v-slot:selection="{ index }">
                                <div v-if="index === 0" class="mr-6">
                                    <span>Quotes</span>
                                </div>
                                <v-badge
                                    v-if="index === 0"
                                    :content="quotesToFilter.length"
                                    :value="quotes"
                                    color="primary"
                                    overlap
                                    class="mt-n2"
                                >
                                </v-badge>
                            </template>
                        </v-combobox>
                    </template>
                    <template v-slot:[`header.reference`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="referenceToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.category`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="categoryToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.quote.promiseDate`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="promiseDateToFilter"
                            dense
                            class="mt-4"
                        >
                            <template v-slot:append>
                                <v-btn
                                    icon
                                    class="mt-n2 text-uppercase"
                                    @click="activateSort('promiseDate')"
                                >
                                    <v-icon v-if="showPromise"
                                        >mdi-arrow-up-bold</v-icon
                                    >
                                    <v-icon v-else>mdi-arrow-down-bold</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </template>
                    <template v-slot:[`header.quote.clientId`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="clientToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.quote.userId`]="{ header }">
                        <v-combobox
                            v-model="usersToFilter"
                            :items="quotesUsers"
                            :label="header.text"
                            flat
                            solo
                            multiple
                            item-text="name"
                            item-value="id"
                            :return-object="false"
                            hide-details
                            background-color="#eeeeee"
                        >
                            <template v-slot:selection="{ index }">
                                <div v-if="index === 0" class="mr-6">
                                    <span>Users</span>
                                </div>
                                <v-badge
                                    v-if="index === 0"
                                    :content="usersToFilter.length"
                                    :value="quotes"
                                    color="primary"
                                    overlap
                                    class="mt-n2"
                                >
                                </v-badge>
                            </template>
                        </v-combobox>
                    </template>
                    <template v-slot:[`header.quote.status`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="statusToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.quote.requestDate`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="requestDateToFilter"
                            dense
                            class="mt-4"
                        >
                            <template v-slot:append>
                                <v-btn
                                    icon
                                    class="mt-n2 text-uppercase"
                                    @click="activateSort('requestDate')"
                                >
                                    <v-icon v-if="showDue"
                                        >mdi-arrow-up-bold</v-icon
                                    >
                                    <v-icon v-else>mdi-arrow-down-bold</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </template>

                    <template
                        v-slot:[`header.quote.invitationDate`]="{ header }"
                    >
                        <v-text-field
                            :label="header.text"
                            v-model="invitationDateToFilter"
                            dense
                            class="mt-4"
                        >
                            <template v-slot:append>
                                <v-btn
                                    icon
                                    class="mt-n2 text-uppercase"
                                    @click="activateSort('invitationDate')"
                                >
                                    <v-icon v-if="showInvitation"
                                        >mdi-arrow-up-bold</v-icon
                                    >
                                    <v-icon v-else>mdi-arrow-down-bold</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </template>
                    <template v-slot:[`header.engineeringDate`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="engineeringDateToFilter"
                            dense
                            class="mt-4"
                        >
                            <template v-slot:append>
                                <v-btn
                                    icon
                                    class="mt-n2 text-uppercase"
                                    @click="activateSort('engineeringDate')"
                                >
                                    <v-icon v-if="showEng"
                                        >mdi-arrow-up-bold</v-icon
                                    >
                                    <v-icon v-else>mdi-arrow-down-bold</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </template>
                    <template v-slot:[`header.engineer`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="engineerToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>

                    <!-- Items -->
                    <template v-slot:[`item.reference`]="{ item }">
                        <div dense class="pt-2">
                            {{ item.reference }}
                        </div>
                    </template>

                    <template v-slot:[`item.quote.clientId`]="{ item }">
                        <div class="my-4">
                            {{ getClientName(item.quote.clientId) }}
                        </div>
                    </template>
                    <template v-slot:[`item.engineer`]="{ item }">
                        <div class="my-4">
                            {{
                                item.engineer ? getUserName(item.engineer) : ''
                            }}
                        </div>
                    </template>
                    <template v-slot:[`item.quote.userId`]="{ item }">
                        <div class="my-0">
                            <div>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <p
                                            class="my-0 font-weight-medium"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{
                                                item.quote.estimatorInfo
                                                    ? item.quote.estimatorInfo
                                                          .name
                                                    : ''
                                            }}
                                        </p>
                                    </template>
                                    <span>Account M.</span>
                                </v-tooltip>
                            </div>
                            <div>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <p
                                            class="my-0 font-weight-medium"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{
                                                item.quote.mainContact
                                                    ? item.quote.mainContact
                                                          .name
                                                    : ''
                                            }}
                                        </p>
                                    </template>
                                    <span>Estimator</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.quote.requestDate`]="{ item }">
                        <div dense class="pt-2">
                            {{ formatDate2(item.quote.requestDate) }}
                        </div>
                    </template>
                    <template v-slot:[`item.quote.promiseDate`]="{ item }">
                        <div dense class="pt-2">
                            {{ formatDate2(item.quote.promiseDate) }}
                        </div>
                    </template>
                    <template v-slot:[`item.quote.invitationDate`]="{ item }">
                        <div dense class="pt-2">
                            {{ formatDate2(item.quote.invitationDate) }}
                        </div>
                    </template>
                    <template v-slot:[`item.engineeringDate`]="{ item }">
                        <div dense class="pt-2">
                            {{
                                formatDate2(item.engineeringDate) ||
                                    item.engineeringDate
                            }}
                        </div>
                    </template>
                    <template v-slot:[`item.quote.status`]="{ item }">
                        <div dense class="pt-2">
                            <v-btn
                                style="width: 90px;"
                                rounded
                                small
                                elevation="0"
                                :color="`${defineColor(item)}`"
                                class="text-center btnStatus white--text ml-2"
                            >
                                {{ defineStatus(item) }}
                            </v-btn>
                        </div>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <div v-if="item">
                            <v-menu rounded offset-y>
                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        depressed
                                        class="ml-2"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-list dense>
                                    <v-list-item @click.stop="openQuote(item)">
                                        <v-list-item-icon>
                                            <v-icon class="mr-1"
                                                >mdi-eye</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Open Quote View
                                        </v-list-item-title>
                                    </v-list-item>

                                    <v-list-item
                                        v-if="
                                            ['OPEN', 'APPROVAL'].includes(
                                                item.quote.status
                                            ) &&
                                                !item.engineering &&
                                                engineeringCostPermission
                                        "
                                        @click.stop="
                                            openSetEngineeringDialog(item)
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon class="mr-1">
                                                mdi-calendar-edit
                                            </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Set Engineering Process
                                        </v-list-item-title>
                                    </v-list-item>

                                    <v-list-item
                                        v-if="
                                            item.engineering == true &&
                                                user.permissions.includes(
                                                    'editEngineeringDueDate'
                                                )
                                        "
                                        @click.stop="openEngDueDateDialog(item)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon class="mr-1"
                                                >mdi-calendar</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Edit Engineering Due Date
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="
                                            ['OPEN', 'APPROVAL'].includes(
                                                item.quote.status
                                            ) &&
                                                item.engineering &&
                                                !item.preDesign.length > 0 &&
                                                (!item.preDesign.length > 0 ||
                                                    (item.preDesign.length >
                                                        0 &&
                                                        item.preDesign[
                                                            item.preDesign
                                                                .length - 1
                                                        ].generatedOn &&
                                                        !item.preDesign[
                                                            item.preDesign
                                                                .length - 1
                                                        ].rejectedOn)) &&
                                                engineeringCostPermission
                                        "
                                        @click.stop="
                                            openEngineeringDialog(item)
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon
                                                v-if="
                                                    item.engineering &&
                                                        engineeringCostPermission
                                                "
                                                class="mr-1"
                                            >
                                                mdi-cancel
                                            </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title
                                            v-if="
                                                item.engineering &&
                                                    engineeringCostPermission
                                            "
                                        >
                                            Return From Engineering
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                </v-data-table>
                <!--Quote Schedule-->
                <v-dialog
                    :retain-focus="false"
                    persistent
                    fullscreen
                    v-model="openSchedule"
                >
                    <QuoteSchedule
                        v-if="openSchedule"
                        :quotes="quotesByCalendar"
                        :settings="settings"
                        @close="closeQuoteSchedule"
                    />
                </v-dialog>

                <!-- COST -->
                <v-dialog
                    :retain-focus="false"
                    :fullscreen="$vuetify.breakpoint.mobile"
                    v-model="dialogCost"
                    max-width="1200px"
                    persistent
                >
                    <NewQuoteCostForm
                        v-if="dialogCost"
                        title="Item Details"
                        :originalCost="originalCost"
                        :cost="editCost"
                        :settings="settings"
                        :quote="selectedQuote"
                        @closeDialog="closeCost"
                        :loading="loading"
                        :readOnly="readOnly"
                        :currency="selectedQuote.currency"
                        :exchange="selectedQuote.exchange"
                        :costExchanges="selectedQuote.costExchanges"
                        @updateCost="updateCost"
                        @updateCosts="updateCosts"
                    />
                </v-dialog>

                <!--QUOTE-->
                <v-dialog
                    :retain-focus="false"
                    v-model="dialogQuote"
                    fullscreen
                    ref="dialogQuote"
                >
                    <v-card v-if="dialogQuote" class="pl-5 ">
                        <v-card-title class="ml-n4">
                            <v-btn icon @click="closeQuote">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            {{ selectedQuote.name }}
                        </v-card-title>
                        <v-card-text
                            class="px-0"
                            :style="{ height: `${this.heightPopUp}px` }"
                        >
                            <Quote
                                :id="selectedQuote.id"
                                :quote="selectedQuote"
                                :settings="settings"
                                :history="false"
                            />
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <!--Edit engineering due Date-->
                <v-dialog
                    v-model="engineeringDateDialog"
                    :retain-focus="false"
                    persistent
                    max-width="400"
                >
                    <v-card :loading="loading" v-if="engineeringDateDialog">
                        <v-card-title class="text-h5">
                            <v-btn
                                small
                                icon
                                @click="closeEngDueDateDialog"
                                color="primary"
                                class="mr-2"
                            >
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <h3 v-if="sendEngineering">
                                Send To Engineering
                            </h3>
                            <h3 v-else-if="changeEngineeringDate">
                                Change Eng Due Date
                            </h3>
                        </v-card-title>
                        <v-divider class="ml-4 mr-5" />
                        <v-card-text class="pt-4">
                            <v-form>
                                <v-row no-gutters>
                                    <v-col cols="12" class="pt-4">
                                        <v-menu
                                            ref="menu"
                                            :close-on-content-click="true"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs,
                                                }"
                                            >
                                                <v-combobox
                                                    class="py-0 my-0"
                                                    v-model="engineeringDate"
                                                    color="primary"
                                                    label="Eng Due Date"
                                                    readonly
                                                    flat
                                                    hide-details
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    prepend-icon="mdi-calendar-blank-outline"
                                                    :rules="[rules.required]"
                                                ></v-combobox>
                                            </template>
                                            <v-date-picker
                                                v-model="engineeringDate"
                                                scrollable
                                                :min="minDate"
                                            >
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                        <v-spacer></v-spacer>
                        <v-card-actions class="pb-4">
                            <v-spacer />
                            <v-btn
                                v-if="sendEngineering"
                                color="primary"
                                :loading="loading"
                                rounded
                                :disabled="!engineeringDate"
                                @click="setEngineering"
                            >
                                CONFIRM
                            </v-btn>
                            <v-btn
                                v-else-if="changeEngineeringDate"
                                color="primary"
                                :loading="loading"
                                rounded
                                :disabled="!engineeringDate"
                                @click="changeEngDueDate"
                            >
                                CONFIRM
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!--Send to engineering-->
                <v-dialog
                    v-model="engineeringDialog"
                    persistent
                    max-width="400px"
                    :retain-focus="false"
                >
                    <v-card :loading="loading" v-if="engineeringDialog">
                        <v-card-title
                            v-if="engineeringDialog && engineering"
                            class="text-h5"
                        >
                            <v-btn
                                small
                                icon
                                @click="closeEngineeringDialog"
                                color="primary"
                                class="mr-2"
                            >
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            Return From Engineering</v-card-title
                        >
                        <v-card-text v-if="engineering">
                            Are you sure you want to return from engineering?
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer />
                            <v-btn
                                v-if="engineering"
                                @click="unSetEngineering"
                                color="primary"
                                rounded
                                :loading="loading"
                            >
                                RETURN
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!--  -->
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import moment from 'moment'
import API from '@/services/api'
import _ from 'lodash'
import * as XLSX from 'xlsx/xlsx.mjs'
export default {
    name: 'Estimating',
    components: {
        Quote: () => import('@/views/Quotes/Quote.vue'),
        QuoteSchedule: () => import('@/components/Quotes/QuoteSchedule.vue'),
        NewQuoteCostForm: () =>
            import('@/components/Quotes/NewQuoteCostForm.vue'),
    },
    data() {
        return {
            openSchedule: false,
            searchParams: null,
            companyId: JSON.parse(localStorage.getItem('company')),
            clients: [],
            usersFiltered: [],
            height: 0,
            headers: [
                {
                    text: 'Quote',
                    value: 'quote.name',
                    align: 'center',
                    sortable: false,
                    width: 95,
                },
                {
                    text: 'Scopes',
                    value: 'reference',
                    align: 'center',
                    sortable: false,
                    width: 100,
                },
                {
                    text: 'Scope Type',
                    value: 'category',
                    align: 'center',
                    width: 125,
                    sortable: false,
                },
                {
                    text: 'Invitation Date',
                    value: 'quote.invitationDate',
                    align: 'center',
                    width: 105,
                    sortable: false,
                },
                {
                    text: 'Due Date',
                    value: 'quote.requestDate',
                    align: 'center',
                    width: 110,
                    sortable: false,
                },
                {
                    text: 'Promise Date',
                    value: 'quote.promiseDate',
                    align: 'center',
                    width: 150,
                    sortable: false,
                },
                {
                    text: 'Client',
                    value: 'quote.clientId',
                    align: 'center',
                    width: 100,
                    sortable: false,
                },
                {
                    text: 'Users',
                    value: 'quote.userId',
                    align: 'center',
                    width: 120,
                    sortable: false,
                },
                {
                    text: 'Status',
                    value: 'quote.status',
                    align: 'center',
                    sortable: false,
                    width: 100,
                },
                {
                    text: 'Eng Due Date',
                    value: 'engineeringDate',
                    align: 'center',
                    sortable: false,
                    width: 120,
                },
                {
                    text: 'Engineer',
                    value: 'engineer',
                    align: 'center',
                    sortable: false,
                    width: 80,
                },
                {
                    text: 'ACTIONS',
                    value: 'actions',
                    sortable: false,
                    width: 110,
                    align: 'center',
                },
            ],
            insights: [
                {
                    color: 'primary',
                    icon: 'mdi-file-document-plus',
                    title: 'Bidding',
                },
                {
                    color: 'green',
                    icon: 'mdi-text-box-search',
                    title: 'Engineering',
                },
                {
                    color: 'orange',
                    icon: 'mdi-file-document-arrow-right',
                    title: 'Approval',
                },
                {
                    color: 'warning',
                    icon: 'mdi-file-document-refresh',
                    title: 'Follow Up',
                },
            ],
            loading: false,
            dialogQuote: false,
            quotes: [],
            loadFollowUp: true,
            selectedQuote: {},
            costs: [],
            quotesToFilter: '',
            referenceToFilter: '',
            categoryToFilter: '',
            promiseDateToFilter: '',
            invitationDateToFilter: '',
            engineeringDateToFilter: '',
            clientToFilter: '',
            accountManagerToFilter: '',
            usersToFilter: [],
            estimatorToFilter: '',
            statusToFilter: '',
            engineerToFilter: '',
            requestDateToFilter: '',
            dialogCost: false,
            originalCost: {},
            editCost: {},
            engineeringDialog: false,
            selectedCost: {},
            settings: [],
            engineering: false,
            startDate: '',
            endDate: '',
            search: null,
            users: [],
            quotesArray: [],
            quotesUsersId: [],
            quotesUsers: [],
            minDate: '',
            parameter: '',
            showInvitation: true,
            showDue: true,
            showPromise: true,
            showEng: true,
            rules: {
                required: v => !!v || 'Required',
            },
            engineeringDate: '',
            engineeringDateDialog: false,
            user: {},
            readOnly: false,
            engineeringCostPermission: false,
            resourceId: undefined,
            editQuote: false,
            changeEngineeringDate: false,
            sendEngineering: false,
        }
    },
    updated() {
        this.onResize()
    },
    provide() {
        return {
            addQuoteForApproval: this.replaceQuote,
            replaceQuote: this.replaceQuote,
            filterQuotes: this.replaceQuote,
            spreadCosts: this.spreadCostsEstimating,
            updateCosts: this.updateCostsLocal,
            replaceQuoteLocal: this.replaceQuote,
        }
    },
    computed: {
        ...mapState(['notificationResource']),
        filters() {
            let conditions = []
            this.sortItems()
            if (this.quotesToFilter) {
                conditions.push(this.filterForQuotes)
            }
            if (this.referenceToFilter) {
                conditions.push(this.filterForReference)
            }
            if (this.categoryToFilter) {
                conditions.push(this.filterForCategory)
            }
            if (this.promiseDateToFilter) {
                conditions.push(this.filterForPromiseDate)
            }
            if (this.invitationDateToFilter) {
                conditions.push(this.filterForInvitationDate)
            }
            if (this.engineeringDateToFilter) {
                conditions.push(this.filterForEngineeringDate)
            }
            if (this.clientToFilter) {
                conditions.push(this.filterForClient)
            }
            if (this.engineerToFilter) {
                conditions.push(this.filterForEngineer)
            }
            if (this.usersToFilter) {
                conditions.push(this.filterForUsers)
            }
            if (this.estimatorToFilter) {
                conditions.push(this.filterForEstimator)
            }
            if (this.statusToFilter) {
                conditions.push(this.filterForStatus)
            }
            if (this.requestDateToFilter) {
                conditions.push(this.filterForRequestDate)
            }
            if (conditions.length > 0) {
                const cost = this.costs
                    .filter(quote => {
                        return conditions.every(condition => {
                            return condition(quote)
                        })
                    })
                    .map((item, index) => ({
                        ...item,
                        ident: `unique-${index}`,
                    }))
                return cost
            }
            return this.costs.map((item, index) => ({
                ...item,
                ident: `unique-${index}`,
            }))
        },
        quotesByCalendar() {
            return [...this.filteredQuotesOpen]
        },
        filteredQuotesOpen: function() {
            return this.filterQuotesBySearch(this.quotes, this.searchParams)
        },
    },
    watch: {
        notificationResource: function(resource) {
            if (resource) {
                this.resourceId = resource
                this.openResource()
                this.setNotificationResource(undefined)
            }
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.resourceId = this.$route.query.resourceId
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            const {
                data: { clients },
            } = await API.getClients()
            this.clients = clients
            const {
                data: { settings },
            } = await API.getSettings()
            this.settings = settings
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            this.engineeringCostPermission = user.permissions.includes(
                'engineeringCost'
            )
            await this.loadData('OPEN,APPROVAL')
            this.minDate = new Date().toISOString()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems', 'replaceQuoteAction']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        activateSort(param) {
            this.parameter = param
            if (this.parameter == 'promiseDate') {
                this.showPromise = !this.showPromise
            } else if (this.parameter == 'requestDate') {
                this.showDue = !this.showDue
            } else if (this.parameter == 'invitationDate') {
                this.showInvitation = !this.showInvitation
            } else if (this.parameter == 'engineeringDate') {
                this.showEng = !this.showEng
            }
        },
        sortItems() {
            if (this.parameter == 'engineeringDate') {
                if (this.showEng) {
                    this.costs.sort((a, b) => {
                        const aValue = a[this.parameter]
                            ? a[this.parameter]._seconds
                            : null
                        const bValue = b[this.parameter]
                            ? b[this.parameter]._seconds
                            : null
                        if (aValue === null) {
                            return bValue !== null ? 1 : 0
                        }
                        if (bValue === null) {
                            return aValue !== null ? -1 : 0
                        }
                        return bValue - aValue
                    })
                } else if (!this.showEng) {
                    this.costs.sort((a, b) => {
                        const aValue =
                            a[this.parameter] && a[this.parameter] != null
                                ? a[this.parameter]._seconds
                                : null
                        const bValue =
                            b[this.parameter] && b[this.parameter] != null
                                ? b[this.parameter]._seconds
                                : null
                        if (aValue === null) {
                            return bValue !== null ? 1 : 0
                        }
                        if (bValue === null) {
                            return aValue !== null ? -1 : 0
                        }
                        return aValue - bValue
                    })
                }
            } else if (this.parameter == 'promiseDate') {
                if (this.showPromise) {
                    this.costs.sort((a, b) => {
                        const aValue = a['quote'][this.parameter]
                            ? a['quote'][this.parameter]._seconds
                            : null
                        const bValue = b['quote'][this.parameter]
                            ? b['quote'][this.parameter]._seconds
                            : null
                        if (aValue === null) {
                            return bValue !== null ? 1 : 0
                        }
                        if (bValue === null) {
                            return aValue !== null ? -1 : 0
                        }
                        return bValue - aValue
                    })
                } else if (!this.showPromise) {
                    this.costs.sort((a, b) => {
                        const aValue =
                            a['quote'][this.parameter] &&
                            a['quote'][this.parameter] != null
                                ? a['quote'][this.parameter]._seconds
                                : null
                        const bValue =
                            b['quote'][this.parameter] &&
                            b['quote'][this.parameter] != null
                                ? b['quote'][this.parameter]._seconds
                                : null
                        if (aValue === null) {
                            return bValue !== null ? 1 : 0
                        }
                        if (bValue === null) {
                            return aValue !== null ? -1 : 0
                        }
                        return aValue - bValue
                    })
                }
            } else if (this.parameter == 'requestDate') {
                if (this.showDue) {
                    this.costs.sort((a, b) => {
                        const aValue = a['quote'][this.parameter]
                            ? a['quote'][this.parameter]._seconds
                            : null
                        const bValue = b['quote'][this.parameter]
                            ? b['quote'][this.parameter]._seconds
                            : null
                        if (aValue === null) {
                            return bValue !== null ? 1 : 0
                        }
                        if (bValue === null) {
                            return aValue !== null ? -1 : 0
                        }
                        return bValue - aValue
                    })
                } else if (!this.showDue) {
                    this.costs.sort((a, b) => {
                        const aValue =
                            a['quote'][this.parameter] &&
                            a['quote'][this.parameter] != null
                                ? a['quote'][this.parameter]._seconds
                                : null
                        const bValue =
                            b['quote'][this.parameter] &&
                            b['quote'][this.parameter] != null
                                ? b['quote'][this.parameter]._seconds
                                : null
                        if (aValue === null) {
                            return bValue !== null ? 1 : 0
                        }
                        if (bValue === null) {
                            return aValue !== null ? -1 : 0
                        }
                        return aValue - bValue
                    })
                }
            } else if (this.parameter == 'invitationDate') {
                if (this.showInvitation) {
                    this.costs.sort((a, b) => {
                        const aValue = a['quote'][this.parameter]
                            ? a['quote'][this.parameter]._seconds
                            : null
                        const bValue = b['quote'][this.parameter]
                            ? b['quote'][this.parameter]._seconds
                            : null
                        if (aValue === null) {
                            return bValue !== null ? 1 : 0
                        }
                        if (bValue === null) {
                            return aValue !== null ? -1 : 0
                        }
                        return bValue - aValue
                    })
                } else if (!this.showInvitation) {
                    this.costs.sort((a, b) => {
                        const aValue =
                            a['quote'][this.parameter] &&
                            a['quote'][this.parameter] != null
                                ? a['quote'][this.parameter]._seconds
                                : null
                        const bValue =
                            b['quote'][this.parameter] &&
                            b['quote'][this.parameter] != null
                                ? b['quote'][this.parameter]._seconds
                                : null
                        if (aValue === null) {
                            return bValue !== null ? 1 : 0
                        }
                        if (bValue === null) {
                            return aValue !== null ? -1 : 0
                        }
                        return aValue - bValue
                    })
                }
            }
        },
        downloadReport() {
            try {
                this.loading = true
                const reportData = []
                for (const cost of this.costs) {
                    reportData.push({
                        Quote: cost.quote.name,
                        Scope: cost.reference,
                        ScopeType: cost.category,
                        InvitationDate: cost.quote.invitationDate
                            ? this.formatDate(
                                  cost.quote.invitationDate._seconds ||
                                      cost.quote.invitationDate.seconds
                              )
                            : '',
                        PromiseDate: cost.quote.promiseDate
                            ? this.formatDate(
                                  cost.quote.promiseDate._seconds ||
                                      cost.quote.promiseDate.seconds
                              )
                            : '',
                        Client: this.getClientName(cost.quote.clientId),
                        AccountManager: this.getUserName(cost.quote.userId),
                        Estimator: this.getUserName(cost.quote.estimatorId),
                        Status:
                            cost.engineering &&
                            cost.engineering == true &&
                            cost.quote &&
                            cost.quote.status != 'SENT'
                                ? 'Engineering'
                                : cost.quote.status == 'OPEN'
                                ? 'Bidding'
                                : cost.quote.status == 'SENT'
                                ? 'Follow Up'
                                : cost.quote.status == 'APPROVAL'
                                ? 'Approval'
                                : '',
                        EngDueDate: cost.engineeringDate
                            ? this.formatDate(
                                  cost.engineeringDate._seconds ||
                                      cost.engineeringDate.seconds
                              )
                            : '',
                    })
                }
                // only array possible
                const reportWS = XLSX.utils.json_to_sheet(reportData)

                // A workbook is the name given to an Excel file
                const wb = XLSX.utils.book_new() // make Workbook of Excel

                // add Worksheet to Workbook
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(wb, reportWS, 'report') // sheetAName is name of Worksheet
                // export Excel file
                XLSX.writeFile(wb, `quotesFollowUp.xlsx`) // name of the file is 'book.xlsx'
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        filterForQuotes(item) {
            if (item && item.quoteId && this.quotesToFilter.length > 0) {
                return this.quotesToFilter.some(
                    quote => quote.id == item.quoteId
                )
            } else {
                return true
            }
        },
        filterForReference(item) {
            if (item.reference) {
                return item.reference
                    .toLowerCase()
                    .includes(this.referenceToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForCategory(item) {
            if (item.category) {
                return item.category
                    .toLowerCase()
                    .includes(this.categoryToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForPromiseDate(item) {
            if (item.quote.promiseDate && item.quote.promiseDate._seconds) {
                const date = this.formatDate(
                    item.quote.promiseDate._seconds
                        ? item.quote.promiseDate._seconds
                        : item.quote.promiseDate.seconds
                        ? item.quote.promiseDate.seconds
                        : 0
                )
                return date.includes(this.promiseDateToFilter)
            } else {
                return false
            }
        },
        filterForInvitationDate(item) {
            if (
                item.quote.invitationDate &&
                item.quote.invitationDate._seconds
            ) {
                const date = this.formatDate(
                    item.quote.invitationDate._seconds
                        ? item.quote.invitationDate._seconds
                        : item.quote.invitationDate.seconds
                        ? item.quote.invitationDate.seconds
                        : 0
                )
                return date.includes(this.invitationDateToFilter)
            } else {
                return false
            }
        },
        filterForClient(item) {
            if (item.quote.clientId) {
                return this.getClientName(item.quote.clientId)
                    .toLowerCase()
                    .includes(this.clientToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForEngineer(item) {
            const user = this.getUserName(item.engineer)
            if (item.engineer && user) {
                return user
                    .toLowerCase()
                    .includes(this.engineerToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForAccountManager(item) {
            if (item.quote.userId) {
                return this.getUserName(item.quote.userId)
                    .toLowerCase()
                    .includes(this.accountManagerToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForUsers(item) {
            if (item && item.quote && this.usersToFilter.length > 0) {
                let validation = this.usersToFilter.every(field =>
                    [item.quote.userId, item.quote.estimatorId].includes(field)
                )
                return validation
            } else {
                return true
            }
        },
        filterForEstimator(item) {
            if (item.quote.estimatorId) {
                return this.getUserName(item.quote.estimatorId)
                    .toLowerCase()
                    .includes(this.estimatorToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForStatus(item) {
            if (item.quote) {
                const status = this.defineStatus(item)
                return status
                    .toLowerCase()
                    .includes(this.statusToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForRequestDate(item) {
            if (item.quote.requestDate && item.quote.requestDate._seconds) {
                const date = this.formatDate(
                    item.quote.requestDate._seconds
                        ? item.quote.requestDate._seconds
                        : item.quote.requestDate.seconds
                        ? item.quote.requestDate.seconds
                        : 0
                )
                return date.includes(this.requestDateToFilter)
            } else {
                return false
            }
        },
        filterForEngineeringDate(item) {
            if (item.engineeringDate && item.engineeringDate._seconds) {
                const date = this.formatDate(
                    item.engineeringDate._seconds
                        ? item.engineeringDate._seconds
                        : item.engineeringDate.seconds
                        ? item.engineeringDate.seconds
                        : 0
                )
                return date.includes(this.engineeringDateToFilter)
            } else {
                return false
            }
        },
        setStatus(status) {
            if (status == 'OPEN') {
                return 'Bidding'
            }
            if (status == 'SENT') {
                return 'Follow Up'
            }
            if (status == 'APPROVAL') {
                return 'Approved'
            }
        },

        async loadData(status) {
            try {
                this.loading = true
                this.menu = false

                //set initial dates
                this.endDate = new Date()
                this.endDate = this.endDate.toISOString().split('T')[0]

                let {
                    data: { quotes },
                } = await API.getQuotesEstimatingPlan(status)
                const indexExchanges = this.settings.findIndex(
                    u => u.name === 'Exchange'
                )
                const { exchanges, globalCurrency } = this.settings[
                    indexExchanges
                ]

                let {
                    data: { costs },
                } = await API.getCostsBigQ()
                costs.forEach(cost => {
                    if (cost.quote) {
                        cost.quote.costExchanges = {
                            exchanges,
                            globalCurrency,
                        }
                        if (cost.quote.userId) {
                            cost.quote.mainContact = this.users.find(
                                user => cost.quote.userId == user.id
                            )
                        }
                        if (
                            cost.quote.estimatorId &&
                            !cost.quote.estimatorInfo
                        ) {
                            cost.quote.estimatorInfo = this.users.find(
                                user => cost.quote.estimatorId == user.id
                            )
                        }
                        cost.quote.createdOn = cost.quote.createdOn
                            ? cost.quote.createdOn
                            : null
                        cost.quote.attached = cost.quote.files
                            ? cost.quote.files.filter(x => x.attach == true)
                            : []
                        cost.quote.docs = cost.quote.files
                            ? cost.quote.files.filter(x => x.attach == false)
                            : []
                        cost.quote.lastUpdate = cost.quote.updatedOn
                        cost.costExchanges = cost.quote.costExchanges
                        cost.quote.clientName = cost.quote.clientId
                            ? this.getClientName(cost.clientId)
                            : ''
                        cost.createdOn = cost.createdOn
                            ? this.formatDate(cost.createdOn._seconds)
                            : null
                    }
                })
                costs = costs.filter(cost => cost.quote !== undefined)
                costs.sort(
                    (a, b) =>
                        b.quote.updatedOn._seconds - a.quote.updatedOn._seconds
                )
                //Only leave quotes with costs
                quotes.forEach(quote => {
                    let exist = costs.find(cost => cost.quoteId == quote.id)
                    if (exist) {
                        this.quotesArray.push({
                            name: quote.name,
                            id: quote.id,
                        })
                        if (quote.estimatorId) {
                            this.quotesUsersId.push(quote.estimatorId)
                        }
                        if (quote.userId) {
                            this.quotesUsersId.push(quote.userId)
                        }
                    } else {
                        // quotes = quotes.filter(q => q.id != quote.id)
                    }
                })
                this.quotesUsersId = [...new Set(this.quotesUsersId)]
                this.quotesUsersId.forEach(user =>
                    this.quotesUsers.push({
                        id: user,
                        name: this.getUserName(user),
                    })
                )
                if (this.quotes.length > 0)
                    this.quotes = this.quotes.concat(quotes)
                else this.quotes = quotes

                if (this.costs.length > 0) {
                    this.costs = this.costs
                        .concat(costs)
                        .map((item, index) => ({
                            ...item,
                            ident: `unique2-${index}`,
                        }))
                } else {
                    this.costs = costs.map((item, index) => ({
                        ...item,
                        ident: `unique3-${index}`,
                    }))
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        sort() {
            this.costs = [...this.costs].sort(
                (a, b) =>
                    b.quote.updatedOn._seconds - a.quote.updatedOn._seconds
            )
        },
        openEngDueDateDialog(item) {
            this.selectedCost = _.cloneDeep(item)
            this.changeEngineeringDate = true
            this.engineeringDateDialog = true
        },
        openSetEngineeringDialog(item) {
            this.selectedCost = _.cloneDeep(item)
            this.sendEngineering = true
            this.engineeringDateDialog = true
        },
        closeEngDueDateDialog() {
            this.selectedCost = {}
            this.engineeringDate = null
            this.engineeringDateDialog = false
            this.changeEngineeringDate = false
            this.sendEngineering = false
        },
        openCost(item) {
            this.originalCost = _.cloneDeep(item)
            this.editCost = _.cloneDeep(item)
            this.selectedQuote = _.cloneDeep(item.quote)
            this.dialogCost = true
        },
        closeCost() {
            this.dialogCost = false
            this.originalCost = {}
            this.editCost = {}
        },
        openQuote(item) {
            this.selectedQuote = _.cloneDeep(item.quote)
            this.dialogQuote = true
        },
        closeQuote() {
            this.selectedQuote = {}
            this.dialogQuote = false
        },
        replaceQuote: function(params) {
            let localQuote = _.cloneDeep(params)
            if (this.selectedQuote) {
                let quoteIndex = this.quotes.findIndex(
                    q => q.id === this.selectedQuote.id
                )
                if (quoteIndex !== -1 && localQuote) {
                    this.quotes.splice(quoteIndex, 1, {
                        ...this.quotes[quoteIndex],
                        ..._.cloneDeep(localQuote),
                    })
                    this.quotes = _.cloneDeep(this.quotes)
                    for (let cost of this.costs) {
                        if (cost.quote.id == this.quotes[quoteIndex].id) {
                            cost.quote = this.quotes[quoteIndex]
                        }
                    }
                }
            }
        },
        spreadCostsEstimating({ ...costs }) {
            let localCosts = _.cloneDeep(costs)
            const quoteId = Object.values(localCosts).find(
                cost => cost.quoteId != undefined
            ).quoteId
            Object.values(localCosts).forEach(cost => {
                const costIndex = this.costs.findIndex(
                    c =>
                        (c.document_id == cost.id ||
                            c.document_id == cost.costId) &&
                        c.quote.id == quoteId
                )
                if (costIndex !== -1) {
                    this.updateCosts(cost)
                }
            })
        },
        updateCostsLocal(costs) {
            let localCosts = _.cloneDeep(costs)
            const quoteId = Object.values(localCosts).find(
                cost => cost.quoteId != undefined
            ).quoteId
            Object.values(localCosts).forEach(cost => {
                let costIndex = -1
                costIndex = this.costs.findIndex(
                    c =>
                        (c.document_id == cost.id ||
                            c.document_id == cost.costId) &&
                        c.quote.id == quoteId
                )
                if (costIndex > -1) {
                    this.costs.splice(costIndex, 1, {
                        ...this.costs[costIndex],
                        ...cost,
                    })
                } else {
                    if (!cost.quoteId) {
                        cost.quoteId = quoteId
                    }
                    if (!cost.quote) {
                        cost.quote = this.costs.find(
                            c => c.quoteId == quoteId && c.quote
                        ).quote
                    }

                    this.costs.push({
                        ...cost,
                        ident: `unique2-${this.costs.length}`,
                    })
                }
            })
        },
        openEngineeringDialog(item) {
            this.selectedCost = _.cloneDeep(item)
            this.engineering = this.selectedCost.engineering
            this.engineeringDialog = true
        },
        closeEngineeringDialog() {
            this.selectedCost = {}
            this.engineering = false
            this.engineeringDate = null
            this.engineeringDialog = false
        },
        async setEngineering(params) {
            try {
                this.loading = true
                let { item, engineeringDate } = params
                if (item) {
                    this.selectedCost = item
                }
                this.selectedCost.engineering = true

                if (engineeringDate) {
                    this.selectedCost.engineeringDate = engineeringDate
                } else {
                    this.selectedCost.engineeringDate = this.engineeringDate
                }

                const cost = await API.forEngineering(
                    this.selectedCost.quoteId,
                    this.selectedCost.document_id,
                    {
                        engineering: this.selectedCost.engineering,
                        engineeringDate: this.selectedCost.engineeringDate,
                        engIngressBy: this.user.id,
                    }
                )

                delete cost.engineeringIngressDate
                delete cost.engineeringExitDate
                delete cost.preDesignReturned
                delete cost.engIngressBy
                delete cost.engExitBy

                this.updateCost(cost)
                if (this.engineeringDateDialog) {
                    this.closeEngDueDateDialog()
                }
                engineeringDate = null
                item = null
                // this.engineeringDate = null
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async unSetEngineering(item) {
            try {
                this.loading = true
                if (item.quoteId || item.document_id) {
                    this.selectedCost = item
                }
                this.selectedCost.engineering = false
                const cost = await API.returnEngineering(
                    this.selectedCost.quoteId,
                    this.selectedCost.document_id,
                    {
                        engineering: false,
                        engExitBy: this.user.id,
                    }
                )
                delete cost.engineeringIngressDate
                delete cost.engineeringExitDate
                delete cost.preDesignReturned
                delete cost.engExitBy
                delete cost.engIngressBy
                this.updateCost(cost)
                if (this.engineeringDialog) {
                    this.closeEngineeringDialog()
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async changeEngDueDate(params) {
            try {
                this.loading = true

                if (params.item && params.engineeringDate) {
                    let { engineeringDate } = params
                    this.selectedCost = this.editCost
                    if (engineeringDate) {
                        this.selectedCost.engineeringDate = engineeringDate
                    }
                } else {
                    this.selectedCost.engineeringDate = this.engineeringDate
                }

                const cost = await API.changeEngDueDate(
                    this.selectedCost.quoteId,
                    this.selectedCost.document_id,
                    {
                        engineeringDate: this.selectedCost.engineeringDate,
                    }
                )
                this.updateCost(cost)
                params = null
                // params = null
                this.closeEngDueDateDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        formatDate(seconds = undefined) {
            return `${moment.unix(seconds).format('YYYY-MM-DD')}`
        },
        formatDate2(date) {
            return date
                ? moment
                      .unix(date._seconds || date.seconds)
                      .format('YYYY-MM-DD')
                : date
                ? date
                : ''
        },
        getUserName(id) {
            if (this.users.length > 0 && id) {
                return this.users.find(user => user.id == id)?.name
            } else {
                return ''
            }
        },
        getClientName(id) {
            if (this.clients.length > 0) {
                return this.clients.find(client => client.id == id)?.name
            } else {
                return ''
            }
        },
        onResize() {
            this.height = window.innerHeight - 112
            this.heightPopUp = window.innerHeight - 62
        },
        updateCost(cost) {
            if (
                cost.engineeringDate &&
                typeof cost.engineeringDate == 'string'
            ) {
                cost.engineeringDate = cost.engineeringDate.substring(0, 10)
            }

            let index = this.costs.findIndex(
                c =>
                    (c.document_id === cost.costId ||
                        c.document_id === cost.document_id ||
                        c.document_id === cost.id) &&
                    c.quoteId == cost.quoteId
            )

            if (index !== -1) {
                this.costs.splice(index, 1, {
                    ...this.costs[index],
                    ...cost,
                })
            }
        },
        updateCosts: async function(item) {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                if (!item.id && item.document_id) {
                    item.id = item.document_id
                }
                delete item.createdOn
                delete item.updatedOn
                delete item.engineeringExitDate
                delete item.preDesignReturned
                delete item.engineeringIngressDate
                delete item.document_name
                delete item.document_id
                delete item.timestamp
                delete item.event_id
                delete item.operation
                delete item.companyId
                delete item.costExchanges
                delete item.index
                delete item.ident
                delete item.quote
                delete item.engExitBy
                delete item.engIngressBy
                delete item.costId
                const cost = await API.updateCosts(item.quoteId, {
                    ...item,
                    ...(item.engineeringDate &&
                    typeof item.engineeringDate == 'object'
                        ? {
                              engineeringDate: moment
                                  .unix(
                                      item.engineeringDate._seconds ||
                                          item.engineeringDate.seconds
                                  )
                                  .format('YYYY-MM-DD'),
                          }
                        : {}),
                })
                if (cost && typeof cost.engineeringDate == 'string') {
                    cost.engineeringDate = cost.engineeringDate.substring(0, 10)
                }
                const index = this.costs.findIndex(
                    c => cost.id == c.document_id
                )
                if (index >= 0) {
                    this.costs.splice(index, 1, {
                        ...this.costs[index],
                        ...cost,
                    })
                } else {
                    this.costs.push(cost)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.closeCost()
                this.loading = false
            }
        },
        openResource() {
            if (this.resourceId) {
                const resource = this.costs.find(
                    c => c.document_id == this.resourceId
                )
                if (resource) {
                    this.resourceId = undefined
                    this.openCost(resource)
                }
            }
        },
        openQuoteSchedule() {
            this.openSchedule = true
        },
        closeQuoteSchedule() {
            this.openSchedule = false
        },
        setClients(quotes) {
            quotes.forEach(quote => {
                let client = undefined
                if (quote.clientId && !quote.client) {
                    client = this.clients.find(
                        client => quote.clientId == client.id
                    )
                    quote.clients = this.clients
                    quote.client = client
                }
            })
        },
        filterQuotesBySearch(quotes, searchParam) {
            this.setClients(quotes)
            let quotesByCollaborators = []
            this.usersFiltered.forEach(element => {
                quotes.forEach(quote => {
                    if (!quotesByCollaborators.find(t => t.id === quote.id)) {
                        if (
                            quote.collaborators &&
                            (quote.collaborators.includes(element.value) ||
                                quote.userId === element.value)
                        ) {
                            quotesByCollaborators.push(quote)
                        }
                    }
                })
            })
            if (
                this.usersFiltered.length == 0 &&
                quotesByCollaborators.length == 0
            ) {
                quotesByCollaborators = _.cloneDeep(quotes)
            }
            let quotesAux = []
            quotesByCollaborators.forEach(quote => {
                if (
                    quote.name
                        .toLowerCase()
                        .includes(searchParam?.toLowerCase()) ||
                    quote.client?.name
                        .toLowerCase()
                        .includes(searchParam?.toLowerCase()) ||
                    quote.number
                        ?.toString()
                        .includes(searchParam?.toLowerCase())
                ) {
                    quotesAux.push(quote)
                }
            })
            if (searchParam) {
                return quotesAux
            }
            return quotesByCollaborators
        },
        filterQuotes() {
            this.quotesSent = this.quotes.filter(
                quote => quote.status == 'SENT'
            )
            this.quotesOpen = this.quotes.filter(
                quote => quote.status === 'OPEN'
            )
            this.quotesApproval = this.quotes.filter(
                quote => quote.status === 'APPROVAL'
            )
        },
        defineStatus(item) {
            if (item.quote) {
                if (
                    ['OPEN', 'APPROVAL'].includes(item.quote.status) &&
                    (item.engineering === false || item.engineering === true)
                ) {
                    return item.preDesign &&
                        item.preDesign[item.preDesign.length - 1]?.rejectedOn
                        ? 'Rejected'
                        : item.engineering &&
                          (['', '123'].includes(item.engineer) ||
                              !item.engineer)
                        ? 'Pending'
                        : item.engineering == false &&
                          item.engineer &&
                          item.preDesign &&
                          item.preDesign.length > 0 &&
                          item.preDesign[item.preDesign.length - 1].generatedOn
                        ? 'Done'
                        : item.engineering &&
                          !['123', undefined].includes(item.engineer)
                        ? 'Assigned'
                        : item.quote.status == 'OPEN'
                        ? 'Bidding'
                        : item.quote.status == 'APPROVAL'
                        ? 'Approval'
                        : ''
                } else if (item.quote.status == 'SENT') {
                    return 'Follow Up'
                } else if (item.quote.status == 'APPROVAL') {
                    return 'Approval'
                } else if (item.quote.status == 'OPEN') {
                    return 'Bidding'
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        defineColor(item) {
            if (item.quote) {
                if (
                    ['OPEN', 'APPROVAL'].includes(item.quote.status) &&
                    (item.engineering === false || item.engineering === true)
                ) {
                    return item.preDesign &&
                        item.preDesign[item.preDesign.length - 1]?.rejectedOn
                        ? 'red'
                        : item.engineering &&
                          (['', '123'].includes(item.engineer) ||
                              !item.engineer)
                        ? 'green'
                        : item.engineering == false &&
                          item.engineer &&
                          item.preDesign &&
                          item.preDesign.length > 0 &&
                          item.preDesign[item.preDesign.length - 1].generatedOn
                        ? 'primary'
                        : item.engineering &&
                          !['123', undefined].includes(item.engineer)
                        ? '#ff7800'
                        : item.quote.status == 'OPEN'
                        ? '#3293e6'
                        : item.quote.status == 'APPROVAL'
                        ? 'orange'
                        : ''
                } else if (item.quote.status == 'SENT') {
                    return 'warning'
                } else if (item.quote.status == 'APPROVAL') {
                    return 'orange'
                } else if (item.quote.status == 'OPEN') {
                    return '#3293e6'
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead th {
    background-color: #eeeeee !important;
}
/* .top {
    background-color: #eeeeee;
} */
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.clickable-row {
    cursor: pointer;
}
</style>
